// src/App.js

import React from 'react';
import CameraWithROI from './components/CameraWithROI';

const App = () => {
  return (
    <div className="App">
      <h1>Camera with ROI Box</h1>
      <CameraWithROI />
    </div>
  );
};

export default App;
