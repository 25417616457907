// src/components/CameraWithROI.js

import React, { useState, useRef, useCallback, useEffect } from 'react';
import Webcam from 'react-webcam';

const CameraWithROI = () => {
  const [isDragging, setIsDragging] = useState(false);
  const [box, setBox] = useState({ startX: 0, startY: 0, width: 0, height: 0 });
  const [croppedImage, setCroppedImage] = useState(null);
  const [facingMode, setFacingMode] = useState('user');
  const webcamRef = useRef(null);
  const roiRef = useRef(null);

  const handleMouseDown = (e) => {
    e.preventDefault();
    const rect = roiRef.current.getBoundingClientRect();
    const startX = e.clientX - rect.left;
    const startY = e.clientY - rect.top;
    setBox({ startX, startY, width: 0, height: 0 });
    setIsDragging(true);
  };

  const handleTouchStart = (e) => {
    e.preventDefault();
    const rect = roiRef.current.getBoundingClientRect();
    const startX = e.touches[0].clientX - rect.left;
    const startY = e.touches[0].clientY - rect.top;
    setBox({ startX, startY, width: 0, height: 0 });
    setIsDragging(true);
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const rect = roiRef.current.getBoundingClientRect();
    const width = Math.min(e.clientX - rect.left - box.startX, rect.width - box.startX);
    const height = Math.min(e.clientY - rect.top - box.startY, rect.height - box.startY);
    setBox((prevBox) => ({ ...prevBox, width, height }));
  };

  const handleTouchMove = (e) => {
    e.preventDefault();
    if (!isDragging) return;
    const rect = roiRef.current.getBoundingClientRect();
    const width = Math.min(e.touches[0].clientX - rect.left - box.startX, rect.width - box.startX);
    const height = Math.min(e.touches[0].clientY - rect.top - box.startY, rect.height - box.startY);
    setBox((prevBox) => ({ ...prevBox, width, height }));
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
  };

  const handleCapture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    cropImage(imageSrc, box);
  }, [webcamRef, box]);

  const cropImage = (imageSrc, box) => {
    const img = new Image();
    img.src = imageSrc;
    img.onload = () => {
      const scaleX = img.width / roiRef.current.clientWidth;
      const scaleY = img.height / roiRef.current.clientHeight;
      const canvas = document.createElement('canvas');
      canvas.width = box.width * scaleX;
      canvas.height = box.height * scaleY;
      const ctx = canvas.getContext('2d');

      if (facingMode === 'user') {
        // Mirror the image on the canvas for front camera
        ctx.translate(canvas.width, 0);
        ctx.scale(-1, 1);
      }

      // Adjust the starting X coordinate for the mirrored image (front camera) or normal (back camera)
      const startX = facingMode === 'user'
        ? img.width - (box.startX * scaleX + box.width * scaleX)
        : box.startX * scaleX;

      ctx.drawImage(
        img,
        startX,
        box.startY * scaleY,
        box.width * scaleX,
        box.height * scaleY,
        0,
        0,
        canvas.width,
        canvas.height
      );
      setCroppedImage(canvas.toDataURL('image/jpeg'));
    };
  };

  const handleRetake = () => {
    setCroppedImage(null);
    setBox({ startX: 0, startY: 0, width: 0, height: 0 });
  };

  const toggleFacingMode = () => {
    setFacingMode((prevMode) => (prevMode === 'user' ? { exact: 'environment' } : 'user'));
  };

  // Prevent scrolling for the live camera area
  useEffect(() => {
    const preventDefault = (e) => e.preventDefault();

    const roiElement = roiRef.current;
    if (roiElement) {
      roiElement.addEventListener('touchstart', preventDefault, { passive: false });
      roiElement.addEventListener('touchmove', preventDefault, { passive: false });
    }

    return () => {
      if (roiElement) {
        roiElement.removeEventListener('touchstart', preventDefault);
        roiElement.removeEventListener('touchmove', preventDefault);
      }
    };
  }, [croppedImage]);

  return (
    <div>
      {!croppedImage && (
        <div
          ref={roiRef}
          style={{ position: 'relative', display: 'inline-block', width: '100%', maxWidth: '640px' }}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width="100%"
            height="auto"
            videoConstraints={{ facingMode }}
            style={{ transform: facingMode === 'user' ? 'scaleX(-1)' : 'none' }}
          />
          <div
            style={{
              position: 'absolute',
              top: box.startY,
              left: box.startX,
              width: box.width,
              height: box.height,
              border: '2px solid red',
              boxSizing: 'border-box',
            }}
          ></div>
        </div>
      )}
      {!croppedImage && (
        <>
          <button onClick={handleCapture}>Capture Image</button>
          <button onClick={toggleFacingMode}>Toggle Camera</button>
        </>
      )}
      {croppedImage && (
        <div>
          <h3>Cropped Image</h3>
          <img src={croppedImage} alt="Cropped" style={{ width: '100%', maxWidth: '640px' }} />
          <button onClick={handleRetake}>Retake Image</button>
        </div>
      )}
    </div>
  );
};

export default CameraWithROI;
